import {
  AGENT_OFFERS,
  SEARCH_URLS,
  SECTIONS,
  VT_SEARCH_URLS,
} from '../../types';
/**
 * Here is where we can add extra data to the query for the different URLs
 * For example we set the section and also the some SEO data.
 * For anything you do not want in the URL please add the
 * key to the REMOVE_FROM_URL list below.
 */
const buildRouteBaseQuery = (routeName: string, query: any) => {
  switch (routeName) {
    case SEARCH_URLS.BUY:
      return {
        query: {
          ...query,
          section: SECTIONS.BUY,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.RENT,
        },
        h1AdType: 'to Rent',
      };
    case SEARCH_URLS.SHARE:
      return {
        query: {
          ...query,
          section: SECTIONS.SHARE,
        },
        h1AdType: 'to Share',
      };
    case SEARCH_URLS.SOLD:
      return {
        query: {
          ...query,
          section: SECTIONS.SOLD,
        },
        h1AdType: 'Sold Properties',
      };
    case 'recent':
      return {
        query: {
          ...query,
          section: 'recent',
        },
        h1AdType: 'recently Added',
      };
    case SEARCH_URLS.NEW_HOMES:
      return {
        query: {
          ...query,
          section: SECTIONS.NEW_HOMES,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.COMMERCIAL_BUY:
      return {
        query: {
          ...query,
          section: SECTIONS.COMMERCIAL_BUY,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.COMMERCIAL_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.COMMERCIAL_RENT,
        },
        h1AdType: 'to Rent',
      };
    case SEARCH_URLS.STUDENT_ACCOMMODATION_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.STUDENT_ACCOMMODATION_RENT,
        },
        h1AdType: 'for Rent',
      };
    case SEARCH_URLS.STUDENT_ACCOMMODATION_SHARE:
      return {
        query: {
          ...query,
          section: SECTIONS.STUDENT_ACCOMMODATION_SHARE,
        },
        h1AdType: 'to Share',
      };
    case SEARCH_URLS.PARKING_BUY:
      return {
        query: {
          ...query,
          section: SECTIONS.PARKING_BUY,
        },
        h1AdType: 'for Sale',
      };
    case SEARCH_URLS.PARKING_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.PARKING_RENT,
        },
        h1AdType: 'to Rent',
      };

    case SEARCH_URLS.HOLIDAY_HOMES:
      return {
        query: {
          ...query,
          section: SECTIONS.HOLIDAY_HOMES,
        },
        h1AdType: '',
      };
    case VT_SEARCH_URLS.PROPERTY_PRICE_REGISTER:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_PROPERTY_PRICE_REGISTER,
        },
      };
    case VT_SEARCH_URLS.RESIDENTIAL_RENT:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_RESIDENTIAL_RENT,
        },
      };
    case VT_SEARCH_URLS.RESIDENTIAL_SALES:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_RESIDENTIAL_SALES,
        },
      };
    case VT_SEARCH_URLS.COMMERCIAL:
      return {
        query: {
          ...query,
          section: SECTIONS.VT_COMMERCIAL,
        },
      };
    case AGENT_OFFERS.RESIDENTIAL_SALES:
      return {
        query: {
          ...query,
          section: SECTIONS.BUY,
        },
      };
    case AGENT_OFFERS.COMMERCIAL_SALES:
      return {
        query: {
          ...query,
          section: SECTIONS.COMMERCIAL_BUY,
        },
      };
    default:
      return { query };
  }
};

export default buildRouteBaseQuery;

export const REMOVE_FROM_URL = [
  'section',
  'h1AdType',
  'agent_id',
  'legacy_agent_id',
];
